@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,300');
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.react-pdf__Document{
  width: 50% !important;
  height: 50% !important;
}

.react-pdf__Page {
  width: 50% !important;
  height: 50% !important;
}

.rowCursorPointer {
  cursor: pointer;
}
